@charset "UTF-8";

.selectHeight  { padding-top: 2rem;}

/* Swann Insurance stlyes */

/*-- GENERICS --*/
body {font-family: 'Arial', Sans-serif; color: #888;}
input, textarea {font-size: 12px; font-family: 'Arial', Sans-serif; color: #6D6D6D;}
hr.clear {visibility: hidden; clear: both; margin: 0;}
.floatLeft {float: left;}
.floatRight {float: right;}
span, strong, a {font-size: 1em; color: inherit;}
a {text-decoration: none;}
a:hover {text-decoration: underline;}
p {line-height: 18px;}
h1, h2, h3 {color: #193571;}
h1 {font-size: 29px; font-weight: bold;}
h2 {font-size: 18px;}
.hidden {display: none;}

/* MODULARS */
/* wrappers */
#wrap {width: 100%; height: 100%; background: url('img/top-blue-bar-background.gif') repeat-x left top;}
#page_wrap {width: 960px; margin: 0 auto; position: relative;}
#content_wrap {min-height: 500px; clear: both;}
#footer_wrap {background: url('img/footer-bar-background.jpg') repeat-x left top #EAEAEA; clear: both;}
#footer_content {width: 960px; height: 360px; margin: 0 auto;}
/* containers */
#header {width: 960px; float: left; clear: both; margin-bottom: 35px;}
#head_bar {height: 47px;}
/* columns */
.fourCol, .threeCol, .twoCol, .oneCol {float: left;}
.fourCol {clear: both; width: 960px; margin-bottom: 55px;}
.threeCol {width: 720px;}
.twoCol {width: 480px;}
.oneCol {width: 240px;}
.oneCol.smaller {width: 180px;}
.oneCol.larger {width: 300px;}
.threeCol.contentCol {width: 620px; margin-right: 100px;}

/*-- SPECIFICS --*/
/* header */
#ph_number {
	font-size: 18px; 
	color: #C5D8EA; 
	background: url('img/talk-to-us-icon.gif') no-repeat left 11px; padding: 14px 40px; 
	float: left;
}

#logo {
	background: url('img/swan-insurance-logo.gif') no-repeat left top; 
	width: 193px; 
	height: 51px; 
	margin-top: 35px; 
	float: left;
}
#logo a {display: block; width: 100%; height: 100%;}

#search_input {
	float: right; 
	background: url('img/search-icon.png') no-repeat 8px center #F7F7F7; 
	border: none; 
	width: 185px; 
	height: 1.7rem;
	padding: 5px 5px 5px 30px;
	margin-top: 10px;
	-moz-border-radius: 5px; /* border radius gecko */
	-webkit-border-radius: 5px; /* border radius webkit */
	border-radius: 5px; /* border radius css3 */
}
#search_input:focus {
	-moz-box-shadow: 0 0 7px #fff; 			/* box shadow gecko */
	-webkit-box-shadow: 0 0 7px #fff;		/* box shadow webkit */
	box-shadow: 0 0 7px #fff;					/* box shadow css3 */
}

#search_submit {
	float: right; 
	margin-top: 10px;
	background: url('img/search-go-button.gif') no-repeat center bottom;
	font-size: 14px; 
	text-weight: bold;
	color: #fff;
	border: none;
	width: 54px;
	height: 27px;
	margin-left: 15px;
	cursor: pointer;
}

#search_submit:hover {background-position: center top;}

#nav {position: relative; float:left;}
#nav li.navItem {float: left; margin-left: 25px; margin-top: 50px; position: relative;}
#nav li.navItem a.navLink {color: #8B8F9E; padding: 18px 20px 12px 20px;}
#nav li.navItem a.navLink:hover, #nav li.active a.navLink {text-decoration: none; color: #fff; background: url('img/nav-rollover-background.gif') no-repeat center center;}

/* Footer */
#footer_content .fourCol {margin-bottom: 0;}
#footer_content .oneCol p.title {
	font-size: 10px;
	font-weight: bold; 
	text-transform: uppercase; 
	color: #A8A8A8; 
	margin-top: 16px;
	margin-bottom: 40px;
}

#footer_content ul {margin-top: 5px; float: left;}
#footer_content ul li {margin-bottom: 10px;}
#footer_content ul li a {color: #606060;}
#footer_content ul li a:hover {text-decoration: underline;}

.showcasePanel {background: url('img/showcase-panel-background.jpg') no-repeat center top; width: 250px; height: 200px; position: relative; top: -5px;}
.showcaseImage {width: 210px; height: 160px; margin: 20px; float: left; position: relative; overflow: hidden; cursor: pointer;}
.showcaseDetails {
	background: url('img/transparent-tile.png') repeat left top;
	width: 180px; 
	position: absolute; 
	right: 0; 
	bottom: 10px; 
	min-height: 40px;
	color: #fff;
}
.showcaseDetails p {font-size: 10px; margin: 8px; line-height: 1.4}

#footer_content .oneCol.larger .title {margin-left: 10px;}

#legal {clear: both; margin-top: 30px; float: left; width: 960px; margin-bottom: 20px;}
#legal p {color: #8C8C8C; margin-bottom: 5px; font-size: 10px; line-height: 1;}
#legal p a {margin-left: 10px;}
#legal p a:hover {text-decoration: underline;}

.newsList {float: left;}
.newsList li {color: #A8A8A8; padding-bottom: 4px;}
.newsList li a {color: #606060; line-height: 1.5;}

/* quad panel */
.quadPanel .panel {
	width: 222px; 
	height: 400px; 
	border: 1px solid #DDD; 
	float: left;
	margin-right: 20px;
	position: relative;
	background-color: #F7F7F7;
	cursor: pointer;
}
.quadPanel .last, .panel.last {margin: 0;}
.panel .readMore {
	width: 90px; 
	height: 40px; 
	background: url('img/read-more-link-background.gif') no-repeat left top; 
	text-align: center;
	position: absolute; 
	left: -9px;
	top: 165px;
}
.panel .readMore a {float: left; color: #fff; padding: 10px 10px 10px 15px;}
.panel h2, .panel p {margin: 20px 20px 0 20px;}
.panel h2 {font-size: 29px; color: #848484; height: 55px;}
.panel p {color: #898989; line-height: 18px;}
.panel h2.singleLine {margin-top: 35px; height: 40px;}
.panel img {position: absolute; bottom: 5px;}

/* general copy */
h3.greenTitle {
	color: #fff; 
	font-size: 19px; 
	background-color: #4BA73C;
	padding:  8px 10px;
	float: left;
	clear: both;
	margin-bottom: 30px;
	-moz-border-radius: 4px; /* border radius gecko */
	-webkit-border-radius: 4px; /* border radius webkit */
	border-radius: 4px; /* border radius css3 */
}
h3.greenSquareTitle {
	color: #fff; 
	font-size: 14px; 
	background-color: #4BA73C;
	padding:  6px 20px;
	clear: both;
	line-height: 26px;
	width:200px;
}
h3.greenSquareTitle.larger {font-size: 18px;}
h3.greenTextTitle {font-size: 23px; color: #4BA73C; font-weight: bold;}
h3#benefits {float: left;}

/* two column copy */
.twoCol p {width: 380px; float: left; line-height: 18px;}
p.headLine {font-size: 24px; margin-bottom: 20px; line-height: 28px;}

/* dashed list */
.dashedList {clear: both;}
.dashedList li {float: left; border-bottom: 1px dashed #999; height: 110px; cursor: pointer;}
.dashedList li img {float: left; width: 150px;}
.dashedList li.last {border: none;}
.dashedList p {width: 320px;}
.dashedList p.title {color: #666; margin-top: 20px;}

/* hero promo */
.hero {position: relative; margin-top: 20px; margin-bottom: 20px; cursor: pointer;}
.hero img {width: 955px;}
.hero .heroCopy {position: absolute; bottom: 25px; right: 30px; color: #FFF;}

.hero h1 {font-size: 24px; margin-bottom: 10px; color: #FFF; font-weight: normal;}
.hero a {font-size: 16px;}

/* Content pages */

p.highlight {font-size: 14px; font-weight: bold; line-height: 20px;}
.contentCol p {margin-bottom: 15px;}

.contentShowcase {margin-top: 45px;}
.contentShowcase h2 {margin-bottom: 25px;}
.contentShowcase .imageBox {width: 175px; height: 170px; background: url('img/content-showcase-background.gif') no-repeat left top; margin-right: 20px;}
.contentShowcase img {margin-top: 11px; margin-left: 11px;}
.contentShowcase p {padding-top: 5px;} 

.contentBanner {text-align: center; margin-top: 25px; margin-bottom: 35px;}

.contentCol h1 {margin: 10px 0;}
.contentCol a {color: #505256;}

/* sidebars */
.sidebarBlock {margin-bottom: 30px;}
.sidebarBlock ul {clear: both;}
.sidebarBlock a {color: #505256}

.blockList li {background-color: #E5E5E5; padding: 16px 20px; border-bottom: 1px solid #ECECEC;}
.blockList li a {color: #73767F; text-shadow: #FFF 1px 0 0;}

.arrowList {padding-top: 20px;}
.arrowList li {padding-bottom: 10px;}
.arrowList li a {padding-left: 20px; background: url('img/arrow-list-icon.gif') no-repeat left center;}
.arrowList.noArrows li a {background: none;}
.arrowList li.moreLink {border-top: 1px solid #CCC; padding: 15px 0;}
.arrowList li.moreLink a {background: none; padding-left: 0px; color: #193571;}

.articleThumbs .thumb {
	margin-top: 20px; 
	margin-right: 21px;
	width: 66px; 
	height: 66px;
	display: block;
	float: left;
}

.articleThumbs .thumb.last {margin-right: 0;}

.sidebarCta .ctaPhNumber {
	color: #193571; 
	font-size: 37px; 
	font-weight: bold; 
	text-shadow: #C1C1C1 0 3px 0; 
	height: 37px; margin-top: 25px; 
	text-align: center;	
}
.sidebarCta h4 {font-size: 15px; font-weight: bold; color: #8B8F9E; text-align: center;}

.bulletList {padding-top: 15px;}
.bulletList li {padding: 3px 0;}
.bulletList li p {background: url('img/bullet-list-icon.gif') no-repeat left 5px; padding-left: 20px;}
.bulletList li.ctaLinks {padding: 20px 10px 20px 5px; border-top: 1px solid #F1F1F1; margin-top: 15px;}
.ctaLinks .callMeBack, .ctaLinks .makeAClaim {padding-left: 22px;}
.ctaLinks .callMeBack {background: url('img/call-me-back-icon.gif') no-repeat left center;}
.ctaLinks .makeAClaim {background: url('img/make-a-claim-icon.gif') no-repeat left center;}

/* Detail pages */
.insureExample {position: relative; top: 10px;}
.insureExample .detailBubble {
	position: absolute;
	right: 0;
	top: 20px;
	background: url('img/detail-bubble-background.gif') no-repeat center center;
	width: 300px;
	height: 190px;
}
.insureExample .bubbleCopy {margin: 30px 60px;}
.insureExample .bubbleCopy h3 {color: #FFF; font-size: 19px; margin-bottom: 15px;}
.insureExample .bubbleCopy p {color: #D6D6D6; margin-bottom: 10px; line-height: 16px;}

/* Accordions */
.accordion {margin-top: 40px; margin-bottom: 60px;}
.accordion h2 {color: #4BA73C; margin-bottom: 20px;}

.accordionItem {border-top: 1px solid #EAEAEA;}
.accordion .last {border-bottom: 1px solid #EAEAEA; margin-bottom: 15px;}
.accordion .grey {background-color: #f8faff;}
.accordionHead {height: 50px; background: url('img/accordion-plus-minus-sprite.gif') no-repeat 585px 15px; cursor: pointer;}
.accordion .expanded {background-position: 585px -185px;}
.accordion .noScript {cursor: auto; background-image: none;}
.accordionBody {padding: 10px 20px 20px 20px;}

.accordionHead a {font-size: 14px; color: #8B8F9E; position: relative; top: 18px; left: 20px;}

a.downloadPolicy {
	color: #505256; 
	padding: 5px 0 5px 30px; 
	background: url('img/download-link-icon.gif') no-repeat left top; 
	font-size: 14px; 
	font-weight: bold; 
	margin-left: 20px;
	margin-top: 5px;
}

.callToAction {
	background: url('img/detail-cta-gradient.gif') repeat-x left top #4ba73c;
	width: 630px;
	height: 156px;
	-moz-border-radius: 5px; /* border radius gecko */
	-webkit-border-radius: 5px; /* border radius webkit */
	border-radius: 5px; /* border radius css3 */
	margin-bottom: 50px;
	position: relative;
}

.ctaCopy {float: left; position: absolute; top: 25px; left: 30px;}
.ctaCopy p {color: #fff; text-shadow: #3B7F05 0 2px 0; font-size: 18px; font-weight: bold; line-height: 22px;}
.ctaCopy p.ctaPhNumber {font-size: 43px; float: left; margin-top: 8px;}
.callToAction img {position: absolute; right: 30px; top: 40px;}
.callToAction .ctaButton {position: absolute; right: 30px; top: 60px;}
.ctaButton a {
	color: #fff;
	font-size: 17px; 
	font-weight: bold;
	padding: 12px 20px 15px 20px;
	display: block;
	background: url('img/cta-button-gradient-tile.gif') repeat-x left top;
	-moz-border-radius: 10px; /* border radius gecko */
	-webkit-border-radius: 10px; /* border radius webkit */
	border-radius: 10px; /* border radius css3 */
	float: left;
}
.ctaButton a:hover {text-decoration: none; color: #DEDEDE; text-shadow: #000 1px 1px 0; opacity: 0.9;}
.sidebarCta .ctaButton {margin-bottom: 15px;}
.sidebarCta .quoteButton {margin-left: 25px}
.sidebarCta .greenTextTitle {text-align: center;}


/* Mega menu */

.megaMenu {
	display: none;
	position: absolute;
	top: 120px; 
	left: -3px;
	z-index: 999;
	width: 960px; 
	height: 300px;
	background: #fff;
	-moz-box-shadow: 0 0 46px #161616; 			/* box shadow gecko */
	-webkit-box-shadow: 0 0 46px #161616;		/* box shadow webkit */
	box-shadow: 0 0 46px #161616;					/* box shadow css3 */
	-moz-border-radius: 5px;						/* border radius gecko */
	webkit-border-radius: 5px; 					/* border radius webkit */
	border-radius: 5px; 								/* border radius css3 */
}

.megaMenu .oneCol {
	background: #DEDEDE;
	height: 300px;
	-moz-border-radius: 0 5px 5px 0;				/* border radius gecko */
	-webkit-border-radius: 0 5px 5px 0; 		/* border radius webkit */
	border-radius: 0 5px 5px 0; 					/* border radius css3 */
	text-shadow: #FFF 0 1px 0;
}

.megaMenu .fourCol {margin-bottom: 0;}

.megaMenu .mmRightCopy {margin: 50px 25px;}
.megaMenu .mmRightCopy h4 {font-size: 16px; color: #193571; font-weight: bold;}
.megaMenu .mmRightCopy ul {margin-top: 20px;}
.megaMenu .mmRightCopy ul li {margin-bottom: 10px;}
.megaMenu .mmRightCopy ul li a {font-size: 14px; color: #64676D;}
.megaMenu .mmRightCopy ul li a:hover {color: #193571; text-decoration: none;}

.megaMenu .megaMenuItems {margin-top: 55px; margin-left: 55px;}
.megaMenu .megaMenuItems .item {width: 300px; height: 120px; float: left;}

.megaMenu .item img {float: left; margin-right: 20px; position: relative; top: -25px;}
.megaMenu .item .itemCopy {float: left;}
.megaMenu .item .itemCopy a.titleLink {font-size: 18px; color: #193571; margin-bottom: 15px; float: left;}
.megaMenu .item .itemCopy a.titleLink:hover {text-decoration: none;}

a.greenButton {
	background: url('img/green-button-gradient-tile.gif') repeat-x left top; 
	padding: 6px 12px 5px 12px; 
	color: #FFF; 
	font-weight: bold;
	-moz-border-radius: 5px;						/* border radius gecko */
	-webkit-border-radius: 5px; 					/* border radius webkit */
	border-radius: 5px; 								/* border radius css3 */
}
a.greenButton:hover {text-decoration: none; color: #DEDEDE; text-shadow: #338924 1px 1px 0;}

/* Lightbox */
#overlay {
	width: 100%; 
	height: 100%; 
	background: #000; 
	position: absolute; 
	z-index: 999; 
	display: none; 
	opacity: 0;
}

#lightbox {
	z-index: 1000 !important;
	position: absolute;
	width: 620px;
	background: #C9C9C9;
	-moz-border-radius: 5px;						/* border radius gecko */
	-webkit-border-radius: 5px; 					/* border radius webkit */
	border-radius: 5px; 								/* border radius css3 */
	-moz-box-shadow: 0 0 46px #000000; 			/* box shadow gecko */
	-webkit-box-shadow: 0 0 46px #000000;		/* box shadow webkit */
	box-shadow: 0 0 46px #000000;					/* box shadow css3 */
}

#lightbox #titleBar {
	position: relative;
	left: -18px;
	height: 55px;
	width: 638px;
	margin-top: 20px;
	background: #4BA73C;
}

#lightbox #titleBar h2 {color: #FFF; font-size: 24px; position: absolute; left: 55px; top: 14px;}
#lightbox #titleBar .cornerFold {position: relative; top: 55px;}

#lightbox #close_button {
	background: url('img/lightbox-close-button.gif') no-repeat left top; 
	height: 30px; 
	width: 30px; 
	display: block;
	position: absolute;
	top: 12px;
	right: 25px;
	text-indent: -9999px;
}
#lightbox .lightboxCopy {margin: 40px; text-shadow: #FFF 0 1px 0; color: #505256;}
#lightbox .lightboxCopy h3 {font-size: 18px; color: #193571; margin-bottom: 20px;}
#lightbox .lightboxForm {margin: 20px 40px 80px 40px;}

#lightbox #titleBar .barShadeGraphic {position: absolute; right: 0;} 

#lightbox .lightboxForm a, #lightbox .lightboxForm label {color: #505256; text-shadow: #FFF 0 1px 0; display: block;}
#lightbox .lightboxForm label {font-size: 16px; margin-bottom: 15px; margin-top: 20px; font-weight: bold;}

#lightbox .acceptTC {margin-top: 30px;}
#lightbox .acceptTC label {font-size: 12px; width: 400px; margin-top: 5px; margin-left: 10px; float: left;}
#lightbox .acceptTC input {float: left;}

#lightbox .lightboxForm input[type="text"], #lightbox .lightboxForm textarea, #lightbox .lightboxForm select {
	padding: 10px;
	background: #EFEFEF;
	border: 1px solid #b0b1b0;
	-moz-border-radius: 3px;						/* border radius gecko */
	-webkit-border-radius: 3px; 					/* border radius webkit */
	border-radius: 3px; 								/* border radius css3 */
}

#lightbox .lightboxForm input[type="text"] {width: 250px; height: 15px;}
#lightbox .lightboxForm textarea {width: 400px; height: 120px;}

#lightbox .lightboxForm input[type="text"]:focus, #lightbox .lightboxForm textarea:focus {border: 1px solid #555;}

#lightbox .lightboxForm div.attachedImage {
	display: block;
	float: left;
	margin-right: 15px;
	height: 70px; 
	width: 70px; 
	overflow: hidden;
	cursor: pointer;
	border: 1px solid #DDD;
	-moz-box-shadow: 0 0 5px #FFFFFF; 			/* box shadow gecko */
	-webkit-box-shadow: 0 0 5px #FFFFFF;		/* box shadow webkit */
	box-shadow: 0 0 5px #FFFFFF;					/* box shadow css3 */
}

#lightbox .lightboxForm div.attachedImage:hover {
	border: 1px solid #FFF;
	background: url('img/attach-image-plus-icon.gif') no-repeat center center;
	-moz-box-shadow: 0 0 15px #FFFFFF; 			/* box shadow gecko */
	-webkit-box-shadow: 0 0 15px #FFFFFF;		/* box shadow webkit */
	box-shadow: 0 0 15px #FFFFFF;					/* box shadow css3 */
}

#lightbox .lightboxForm div.hasImageAttached {
	background: url('img/remove-image-rollover.gif') no-repeat center center;
	-moz-box-shadow: 0 0 5px #000000; 			/* box shadow gecko */
	-webkit-box-shadow: 0 0 5px #000000;		/* box shadow webkit */
	box-shadow: 0 0 5px #000000;					/* box shadow css3 */
}

#lightbox .lightboxForm div.hasImageAttached:hover {
	background: url('img/remove-image-rollover.gif') no-repeat center center;
	border: 1px solid #800;
	-moz-box-shadow: 0 0 5px #880000; 			/* box shadow gecko */
	-webkit-box-shadow: 0 0 5px #880000;		/* box shadow webkit */
	box-shadow: 0 0 5px #880000;					/* box shadow css3 */
}

#lightbox .lightboxForm div.attachedImage img {
	height: 70px;
}

#lightbox .lightboxForm a.attachImage {margin-top: 10px; font-weight: bold;}

#lightbox input.greenButton {
	background: url('img/green-button-gradient-tile.gif') repeat-x left top; 
	padding: 4px 20px 4px 20px; 
	color: #FFF; 
	font-weight: bold;
	-moz-border-radius: 5px;						/* border radius gecko */
	-webkit-border-radius: 5px; 					/* border radius webkit */
	border-radius: 5px; 								/* border radius css3 */
	border: none;
	font-size: 13px;
	cursor: pointer;
}

#lightbox .lightboxForm select {
	margin-bottom: 30px;
	font-weight: bold;
	width: 270px; 
	height: 37px;
	font-size: 12px;
}

/* RATINGS tweaks */
#pageRatings .voting p, #pageRatings .voting a {padding-top: 3px;}
#pageRatings .ratingButtons .send span, #pageRatings .ratingButtons .cancel span {padding-top: 10px;}
