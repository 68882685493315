@charset "utf-8";

/* CSS Document */

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent}
body{line-height:1; font-size:12px;}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}:focus{outline:0}ins{text-decoration:none}del{text-decoration:line-through}table{border-collapse:collapse;border-spacing:0}


/* BASIC TYPOGRAPHY */
body, .ms-WPBody { font-family: Arial, Helvetica, sans-serif;}
cite, em, dfn { font-style: italic; }

del { text-decoration: line-through; color: #666; }
ins, dfn { border-bottom: 1px solid #ccc; }
small, sup, sub { font-size: 85%; }
abbr, acronym { text-transform: uppercase; font-size: 85%; letter-spacing: .1em; }
a abbr, a acronym { border: none; }
abbr[title], acronym[title], dfn[title] { cursor: help; border-bottom: 1px solid #ccc; }
sup { vertical-align: super; }
sub { vertical-align: sub; }

/* LISTS */

/*ul li { margin-left: .85em; }
ul { list-style-type: disc; }
ul ul { list-style-type: square; }
ul ul ul { list-style-type: circle; }
ol { list-style-position: outside; list-style-type: decimal; }
dt { font-weight: bold; }*/


/* QUOTES */

blockquote { border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; color: #666; }
blockquote *:first-child:before { content: "\201C"; }
blockquote *:first-child:after { content: "\201D"; }

/* FORMS */

legend { font-weight: bold; font-size:1.2em; }
label { font-weight: bold; }
textarea, input[type='text'], input[type='password'], select { border: 1px solid #ccc; background: #fff; }
textarea:hover, input[type='text']:hover, input[type='password']:hover, select:hover { border-color: #4CBCC7; }
textarea:focus, input[type='text']:focus, input[type='password']:focus, select:focus { border: 1px solid #4CBCC7; }
input, select { cursor: pointer; }
input[type='text'],input[type='password'] { cursor: text; }


/* TABLES */

/*
table {  }
th, td { border-bottom: 1px dashed #B0A1A4; }
*/


/* BASE SIZES */

.ms-WPBody { font-size: 12px; line-height:1.6em}
p { font-size:12px;}
#contentWrapper table td {
	font-size: 14px!important;
	font-family: Arial, Helvetica, sans-serif!important;
}

h1 { font-size: 35px; }
h2, .ms-WPBody h2 { font-size: 30px; letter-spacing:-1px; }
h3, .ms-WPBody h3 { font-size: 20px; }
h4, .ms-WPBody h4 { font-size: 14px; font-weight:bold; color:#065E9D!important; }
h5, .ms-WPBody h5 { font-size: 16px; }
h6, .ms-WPBody h6 { font-size: 16px; }

h1 { line-height: 1.0em; } /*change to 1em from 40px to fix the Sfir size issue*/
h2 { line-height: 30px; }
h3 { line-height: 15px; }
h4 { line-height: 20px; }
h5 { line-height: 18px; }
h6 { line-height: 16px; }

/* MARGINS & PADDINGS */

blockquote *:first-child { margin: .8em 0; }
hr, p, ul, ol, dl, pre, blockquote, address, /*table, by lewis*/ form { margin-bottom: 15px; }
/* NOTE: Calulate header margins: TOP: 1.6em/size, BOTTOM: 1.6em/size/2 */
h1 { margin: 0 0 15px 0;  }
h2 { margin: 0 0 13px 0; }
h3 { margin: 0 0 14px 0; }
h4 { margin: 0 0 9px 0; }
h5 { margin: 0 0 9px 0; }
h6 { margin: 0 0 5px 0; }
/*th, td { padding: .8em; } by lewis*/
caption { padding-bottom: .8em; } /* padding instead of margin for IE */
blockquote { padding: 0 1em; }
legend { padding-left: .8em; padding-right: .8em; }
legend+* { margin-top: 1em; } /* compensates for the opera margin bug */
legend+* { margin-top: 1em; } /* compensates for the opera margin bug */
textarea, input { padding: .3em .4em .15em .4em; }
select { padding: .1em .2em 0 .2em; }
option { padding: 0 .4em; }
dt { margin-top: .8em; margin-bottom: .4em; }
ol { margin-left: 2.35em; }
ol ol, ul ol { margin-left: 2.5em; }
/*form div { margin-bottom: .8em; } by Lewis*/
ul { padding-left:15px; }


/* COLORS */

body{ color:#87888A; }
a, a:link { text-decoration: none; color:#333333 !important; }
a:visited { text-decoration:none; color:#8c97d2; }
a:hover, a:visited:hover, p.addtionalPromoLine a:hover { text-decoration: none !important; color:#2E8ABF !important; }
a:active, a:focus { text-decoration: none; color: #8c97d2; }
/*a:visited{ color:#586A99;}*/
code, pre { color: #c33; } /* very optional, but still useful. W3C uses about the same colors for codes */
h1,h2,h3,h4,h5,h6{ color:#80A1C2; font-weight:normal !important;}


/*UTILITIES*/
hr {background:#ddd;color:#ddd;clear:both;float:none;width:100%;height:.1em;margin:0 0 1.45em;border:none;}
.clear{ clear:both; height: 0; }
.imgGoLeft{ float:left; margin:0 10px 0 0;}
.imgGoRight{ float:right; margin:0 0 10px 0;}
.noBorder{ border:none;}
.borderBottom{ border-bottom:1px solid #D8F0F2;}
.lastBox{ margin-bottom:0;}
.clear {clear:both;}
.marginTop10 { margin-top:10px; }
.marginTop20 { margin-top:20px; }
.marginLeft30 { margin-left:30px; }
.paddingTop15{ padding-top:15px;}
.paddingTop20{ padding-top:20px;}
.paddingTop40{ padding-top:40px;}
/*talkBtntab*/
.marginBottom30{ margin-bottom:30px;}
.marginBottom15{ margin-bottom:15px;}
.marginBottom25{ margin-bottom:25px;}

.marginRight30{ margin-right:30px;}
.marginRight45{ margin-right:45px;}
.marginRight20{ margin-right:20px;}
.last{ margin-right:0}
.yellowFont{ color:#EBBD65 !important; font-weight:normal;}
.yellowBoldFont{ color:#EBBD65 !important;}
.marginRight36{ margin-right:36px;}
.clearfix:after { visibility: hidden; display: block; font-size: 0; content: " "; clear: both; height: 0;}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
.paddingBottom80{ padding-bottom:80px;}
/* close commented backslash hack */
.floatLeft{ float:left;}
.marginRight72{ margin-right:72px;}
.floatRight{ float:right;}
.blueHeading{ font-size:22px !important; font-weight:bold !important; color:#0D4987!important;}
.marginLeft65{ margin-left:65px;}
.if-js-show { display:none; }
.marginRight17{ margin-right:17px;}
.marginRight15{ margin-right:15px;}


/* TEXT CLASSES */

.small {font-size:.8em;margin-bottom:1.875em;line-height:1.875em;}
.large {font-size:1.2em;line-height:2.5em;margin-bottom:1.25em;}
.hide {display:none;}
.quiet {color:#666;}
.loud {color:#000;}
.highlight {background:#ff0;}
.top {margin-top:0;padding-top:0;}
.bottom {margin-bottom:0;padding-bottom:0;}
.thin {font-weight: lighter;}
.error, .notice, .success {padding:.8em;margin-bottom:1.6em;border:2px solid #ddd;}
.error {background:#FBE3E4;color:#8a1f11;border-color:#FBC2C4;}
.notice {background:#FFF6BF;color:#514721;border-color:#FFD324;}
.success {background:#E6EFC2;color:#264409;border-color:#C6D880;}
.error a {color:#8a1f11; background:none; padding:0; margin:0; }
.notice a {color:#514721; background:none; padding:0; margin:0; }
.success a {color:#264409; background:none; padding:0; margin:0; }
.center {text-align: center;}
.rightBleed{ margin-right:-30px; float:right}

/*Cols*/



/* PAGE LAYOUT */

#s4-bodyContainer{ background:url('../images/main-bg.gif') repeat-x scroll 0 13px;}

#s4-workspace {
	line-height:1.6em;
}


/* Navigation */

#navigation{ width:100%; float:left; z-index:999; background-color: #fff; padding:12px 0 0 0;}
#navWrapper{ width:960px; margin:0 auto; }
#mainNav, #secNav{ float:left; margin-bottom:0!important; padding-left:0px;}
#mainNav li{ display:inline;}
#mainNav li a{ color:#333333; font-size:16px; padding:10px 19px; border-right:1px solid #FFFFFF; line-height: 13px;} 
#mainNav li a:hover{ background:url('../images/nav-bg.gif') repeat-x 0 0; border-right:1px solid #DCDCDC; color: #333333 !important;}
#mainNav li a.selected, .currentPage{ background:url('../images/nav-bg.gif') repeat-x 0 0; border-right:1px solid #DCDCDC!important;}

#secNav{ float:right; padding-top: 8px; }
#secNav li{ display:inline;}
#secNav li a{ color:#333333; font-size:12px; border-left:1px solid #DCDCDC; padding:12px 15px 11px 15px;}
#secNav li a:hover, #breadcrumb li a:hover{ color:#2E8ABF;}



/* banner */

#banner{ width:100%; float:left; z-index:99;}
#bannerWrapper{ width:960px; margin:0 auto;}
#canvas{ display:block; width:960px; height:230px; /* background:url('../images/home-bannner.jpg') no-repeat 0 0; */ position:relative;}
#canvasProduct{ display:block; width:960px; height:289px; background:url('../images/product-banner.jpg') no-repeat 0 0; position:relative;}
#canvasHelp{ display:block; width:960px; height:289px; background:url('../images/claim-banner.jpg') no-repeat 0 0; position:relative;}
#canvasSearch{ display:block; width:960px; height:289px; background:url('../images/claim-banner.jpg') no-repeat 0 0; position:relative;}
#canvasGeneral{ display:block; width:960px; height:289px; background:url('../images/product-banner.jpg') no-repeat 0 0; position:relative;}
#canvasClaim{ display:block; width:960px; height:289px; background:url('../images/claim-banner.jpg') no-repeat 0 0; position:relative;}
#canvasAbout{ display:block; width:960px; height:289px; background:url('../images/about-banner.jpg') no-repeat 0 0; position:relative;}
#canvasLegacyProduct{ display:block; width:960px; height:289px; background:url('../images/legacy-product-banner.jpg') no-repeat 0 0; position:relative;}
#logo a{ display:block; width:156px; height:143px; background:url('../images/logo.png') no-repeat 0 0; text-indent:-9999px; position:absolute; top:26px;}
#contactBanner{ display:block; width:250px; height:86px; background:url('../images/contact.png') no-repeat 0 0; position:absolute; right:-10px;top:28px; font-size:18px; color:#FFFFFF; line-height:1.15em; text-shadow: 0px 2px 0px #022c4f;}
#contactBanner p { margin:10px 0px 0px 24px; font-size:14px; letter-spacing:0.05em; }
#contactBanner p strong { font-size:16px; text-shadow:none;  letter-spacing:0.08em;}
#tagLine{ position:absolute; left:0; bottom:0; width:960px; background:url('../images/tagBg.png') 0px 0px no-repeat; height:50px; color:#FFFFFF;}
#tagLine h1 { font-size:32px; color:#FFFFFF; font-size:25px; padding-top:13px; padding-left:43px;}


/* info bar */

#infoBar{ width:100%; float:left;}
#infoBarWrapper{ width:960px; margin:0 auto; height:30px; background-color:#ECE8E7; padding:0}
#breadcrumb{ margin: 6px 0 0 28px; float:left; width:567px;  list-style:none; }
#breadcrumb span span {
	margin-right: 3px;
}
#breadcrumb span a {
	background:url("../images/arrow.gif") no-repeat scroll right 3px transparent;
	padding-right: 18px;
	text-decoration: underline!important;
} 
#breadcrumb li{ color:#999896; padding-top:6px; float:left;}
#breadcrumb li a{ margin-right:10px; color:#333;  display:block; float:left; background:url('../images/arrow.gif') no-repeat right 6px; padding-right:20px; text-decoration:underline;}
.searchInput{ display:block; width:254px; height:14px; float:left; margin-right:7px; margin-top:3px;}
#infoBarWrapper .submitBtn, #infoBarWrapper .searchInput { margin-top:5px; }
#infoBarWrapper .submitBtn { padding:2px 10px; margin-top:3px; float:left;  -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px;}
.submitBtn{ background: #4B79B4; /* old browsers */
background: -moz-linear-gradient(top, #4B79B4 0%, #084B7F 100%); /* firefox */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#4B79B4), color-stop(100%,#084B7F)); /* webkit */
/*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4B79B4', endColorstr='#084B7F',GradientType=0 );*/ /* ie */
border:none; color:#FFFFFF; padding:2px 10px; -webkit-border-radius: 3px;
-moz-border-radius: 3px;
border-radius: 3px;
}
.submitBtn:hover{ color:#eee; cursor:pointer;}


a.submitBtn{ background: #4B79B4; /* old browsers */
background: -moz-linear-gradient(top, #4B79B4 0%, #084B7F 100%); /* firefox */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#4B79B4), color-stop(100%,#084B7F)); /* webkit */
/*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4B79B4', endColorstr='#084B7F',GradientType=0 );*/ /* ie */
border:none; color:#FFFFFF !important; padding:8px; -webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px; color:#FFFFFF;
}

a.submitBtn span {padding:0px 5px; }

a.submitBtn:hover { 
background: #4d7cb6; /* old browsers */
background: -moz-linear-gradient(top, #7e9ad0 0%, #4d7cb6 100%); /* firefox */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#7e9ad0), color-stop(100%,#4d7cb6)); /* webkit */
color:#eee;
}



/* offers */

#offers{ width:100%; float:left;}
#offersWrapper{ width:960px; margin:0 auto; padding:10px 0 6px;}
p.addtionalPromoLine {  font-size:14px; padding-top:6px;}
#offersWrapper h3, p.addtionalPromoLine { padding-left:5px; }
p.addtionalPromoLine a { color:#333 !important; text-decoration:underline !important;}
p.addtionalPromoLine a:hover { text-decoration:underline; }
.offer a{ display:block; width:292px; height:165px; float:left; margin-top:6px; font-size:24px; text-align:center; color:#333333; margin-bottom:30px; border:12px solid #ECE8E7;}
.offer a:hover, .offer a:visited, .offer a:visited:hover { color:#333333; }
.marginRight6{ margin-right:6px;}

.subOffer a{ display:block; width:280px; height:70px; float:left; margin-top:24px; font-size:18px; color:#333333;}
.subOffer a:visited { color:#333; }
.subOffer a:visited:hover { color:#2E8ABF; }

.subOffer a span { margin-top:20px; float:left; padding-left:20px;}
.subOffer a img { float:left; }
	/*
.subOffer a:hover{ color:#999999;}*/
/*
.offerTravel{ background:url('../images/offer-travel.jpg') no-repeat 0 0; }
.offerMotorbike{ background:url('../images/offer-motorbike.jpg') no-repeat 0 0; }
.offerMotorboat{ background:url('../images/offer-Motorboat.jpg') no-repeat 0 0; }
.offerSmallbusiness{ background:url('../images/offer-smallbusiness.jpg') no-repeat 0 0; }
*/

.mainOffer a, .secondOffer a, .thirdOffer a {
	display:block; float:left; border:12px solid #ECE8E7;
}

.mainOffer a{  width:605px; height:237px;  margin-bottom:10px; margin-right:25px;}
.secondOffer a {  width:280px; height:100px; margin-bottom:12px;}
.thirdOffer a { width:280px; height:100px;  margin-bottom:0; }



.mainOffer a:hover, .secondOffer a:hover, .thirdOffer a:hover, .offer a:hover{ border:12px solid #ACACAC;}

/* extra infor */

#extras{ width:100%;}
#CommChannelsWrapper{ width:100%; float:left;}
#extrasWrapper, #CommChannelsWrapper{ width:960px; margin:42px auto;}
#extrasWrapper>table, #CommChannelsWrapper>table {
	width:auto!important;
	margin:0px auto;
}
.triCol{ width:260px; float:left; background:url('../images/extra-bg.jpg') repeat-x 0 0 #F7F7F7; padding:10px 25px 10px 31px;}
#CommChannelsWrapper .ms-WPHeader, #rightHandLinks  .ms-WPHeader {
    background-color: #ECE8E7;
    padding:10px 25px 10px 31px;
}
#ProductDetailExpandable .ms-WPHeader h3.ms-WPTitle{
    color: #95807F!important;
    margin-bottom: 0;	
    line-height:18px;
    font-size:16px!important;
   	font-family: Arial, Helvetica, sans-serif!important;
}
#ProductDetailExpandable .ms-WPHeader td{
	border-bottom: 0px;
}
#ProductDetailExpandable .ms-WPHeader {
		background:transparent;
}

#ProductDetailExpandable #openAllLink{
	margin-top: -22px;
	font-size:12px;
}

#ProductDetailExpandable .accordionHead a {
	position: absolute;
}
#FAQExpandable .ms-WPHeader h3.ms-WPTitle{
    color: #0D4987 !important;
    font-size: 22px!important;
    font-weight: bold!important;	
    line-height:18px;
   	font-family: Arial, Helvetica, sans-serif!important;
}
#FAQExpandable .ms-WPHeader td{
	border-bottom: 1px dashed #EBEBEB;
	padding-bottom: 8px;
}
#FAQExpandable .ms-WPHeader{
	background:transparent;
}

#FAQExpandable #openAllLink{
	margin-top: -30px;
	font-size:12px;
}
#FAQExpandable .accordionBody ul
{
    padding-left: 35px;
}
#FAQExpandable .accordionBody ul li
{
    list-style-type:disc;
}
#CommChannelsWrapper .ms-WPHeader .ms-WPTitle, #rightHandLinks  .ms-WPHeader .ms-WPTitle {
	color: #065E9D !important;
    font-size: 14px!important;
    font-weight: normal;
    padding: 12px 0 12px 20px;
	font-family: Arial, Helvetica, sans-serif!important;
}
#CommChannelsWrapper .s4-wpTopTable,#rightHandLinks  .s4-wpTopTable {
	background-color:#F7F7F7;	
}
#rightHandLinks .ms-WPHeader .ms-WPTitle {
	font-weight:bold !important;
}

#rightHandLinks .ms-WPBody {
	margin: 10px 25px 10px;
}
#rightHandLinks .ms-WPBody .dfwp-column .slm-layout-main {
	margin: -10px 25px 10px -25px;
}

#extrasWrapper .triCol, #CommChannelsWrapper .triCol{ padding:10px 25px 10px 0px; width:291px;}
#extrasWrapper .triCol a, #CommChannelsWrapper  .triCol a{ font-size:16px; }
#extrasWrapper .triCol li a, #CommChannelsWrapper .triCol li a{ font-size:14px; }
.fixedHeight{ height:200px!important;}
.triCol h4{ margin-bottom:28px;}
#extrasWrapper .triCol h4, #CommChannelsWrapper .triCol h4 { padding-left:25px; }
#extrasWrapper .triCol ul , #CommChannelsWrapper .triCol ul{ padding-left:25px; }
.triCol ul.infoList li{ list-style:none; border-bottom:1px dashed #D2DEEA; padding:5px 0;}
.triCol ul.infoList li:last-child{ border-bottom:none;}
.triCol ul.infoList li.contactNumber{ font-size:24px; color:#075D9A; padding-top:10px; letter-spacing:0.03em; padding-bottom:24px;}
.email-icon{ background:url('../images/email-icon.gif') no-repeat 0 0; padding-left:30px;}
.talk-icon{ background:url('../images/talk-icon.gif') no-repeat 0 0; padding-left:30px;}
.dotsBg{ background:url('../images/dots-bg.gif')  repeat-y top right;}
.noBg{ background:none;}

#CommChannelsWrapper .Communication-Header, #rightHandLinks .Communication-Header{ 
    border-bottom: 1px dashed #D2DEEA;
    color: #075D9A;
    font-size: 24px;
    letter-spacing: 0.03em;
    margin: 30px 6px -17px 25px;
    padding-bottom: 20px;
}

#rightHandLinks .Communication-Header {
	border-bottom: 0px;
}

#CommChannelsWrapper .Communication-LinkItem, #rightHandLinks .Communication-LinkItem{    
	 border-top: 1px dashed #D2DEEA;
    list-style: none outside none;
    padding: 5px 0;
    margin: 0 6px 0 25px;

}

#rightHandLinks .Communication-LinkItem{    
	 border-top: 0px;
}

#CommChannelsWrapper  .ms-WPBody ul.dfwp-list li:first-child .Communication-LinkItem, #rightHandLinks .ms-WPBody ul.dfwp-list li:first-child .Communication-LinkItem{
	border: 0px;
	margin-top: 20px;	
}

#CommChannelsWrapper .Communication-LinkItem .link-item a {
	font-size:14px;
	font-family:Arial, Helvetica, sans-serif;
}
#rightHandLinks .Communication-LinkItem .link-item a {
	font-size:12px;
	font-family:Arial, Helvetica, sans-serif;
}

#CommChannelsWrapper .Communication-LinkItem .image-area-left, #rightHandLinks .Communication-LinkItem .image-area-left {
	margin-top: 3px;
	width: 25px
}

#CommChannelsWrapper .ms-WPBody {
	margin-bottom: 40px;
	min-height: 142px;
}
 
/* question */
#question{ width:100%; float:left;}
#questionWrapper{ width:898px; margin:0 auto; background:url('../images/strip.jpg') repeat-y 0 0; padding:34px 18px 25px 32px; margin-bottom:15px;}
#questionWrapper h3{ font-size:24px; color:#333333; margin-bottom:12px;}
#questionWrapper p{ color:#806C6B; margin-bottom:0;}
#questionWrapper p a { text-decoration:underline;}


/* Benefits */

#benefits{ width:100%; float:left;}
#benefitsWrapper{ width:960px; margin:30px auto;}
#benefitsWrapper h3 { padding-left:5px; margin-bottom:30px; }

.col4{ width:186px; float:left; text-align:center;}
#benefitsWrapper .col4 {text-align:left; }
.col4 img{ margin-bottom:10px;}
.col4 p{ color:#085C9A!important; font-size:16px; }




/* Call us */

#callUs{ width:100%; float:left;}
#callUsWrapper{ width:910px; margin:45px auto 13px auto; border-bottom:1px dashed #F1F1F1;  border-top:1px dashed #F1F1F1; padding:15px 25px 6px 25px;}
#callUsWrapper h3, #callUsWrapper h4, #callUsWrapper .submitBtn{ float:left;}
#callUsWrapper h3{ margin-right:92px; font-size:26px; color:#666666; font-style:italic; margin-top:10px; line-height:1.6em; margin-left:34px;}
#callUsWrapper h4{ margin-right:140px; font-size:26px; color:#0D4987; margin-top:20px; font-weight:normal;}
.talkBtn a{ width:137px; height:18px; background:url('../images/talk-btn.jpg') no-repeat 0 0; font-size:14px; font-weight:bold; display:block; float:left; color:#FFFFFF !important; padding:15px; margin-top:5px;}
.talkBtn a:hover, .talkBtn a:visited:hover { color:#eee; }
#callUsWrapperSmall{ width:550px; margin:0px auto 30px auto; border-bottom:1px dashed #F1F1F1;  border-top:1px dashed #F1F1F1; padding:10px 0px 25px 15px;}
#callUsWrapperSmall h3, #callUsWrapperSmall h4, #callUsWrapperSmall .submitBtn{ float:left;}
#callUsWrapperSmall h3{ margin-right:20px; font-size:20px; color:#666666; font-style:italic; margin-top:17px; line-height:1.8em;}
#callUsWrapperSmall h4{ margin-right:30px; font-size:20px; color:#0D4987; margin-top:25px;}

.talkBtn a:hover{ background-position:0px -89px; }


.talkBtn1 a{ width:137px; height:18px; background:url('../images/talk-btn.jpg') no-repeat 0 0; font-size:14px; font-weight:bold; display:block; float:left; color:#FFFFFF !important; padding:15px; margin-top:5px;}
.talkBtn1 a:hover, .talkBtn a:visited:hover { color:#eee; }
.talkBtn1 a:hover{ background-position:0px -89px; }
/* footer */

#footer{ width:100%; height:260px; float:left; background-color:#064F84; margin-top:40px; padding-top:30px;}
#footerWrapper{ width:960px; margin:0 auto;}
.blueDotsBg{ background:url('../images/blueDots-bg.gif')  repeat-y top right;}
ul.footerLinks{ width:136px; float:left; padding-left:0px; margin-top:5px;}
ul.footerLinks li{ list-style:none; margin-bottom:2px;}
ul.footerLinks li a{ color:#CCCCCC !important;}
ul.footerLinks li a:hover{ color:#FFFFFF !important;}
ul.footerLinks li h5 { margin-bottom:14px; }
ul.footerLinks li h5, ul.footerLinks li h5 a { color:#fff !important; font-style:italic !important; }
ul.footerLinks li h5 a { color:#fff !important; font-style:italic !important; }
ul.footerLinks li h5 a:hover { text-decoration:underline !important; }
#footerWrapper .triCol { width:296px; padding:10px 0px 10px 20px; }


/* warning */

#warning{ width:100%; float:left;}
#warningWrapper{ width:886px; margin:30px auto; border:12px solid #ABC1D6; height:170px; padding:25px;}
#warningLinks{ float:right; width:210px; background-color:#FCFCFC;  margin:-25px -25px -25px 50px; height:170px; padding:15px 20px 20px;}
#warningLinks li{ list-style:none;  border-top:1px dashed #D2DEEA; padding:5px 0;}
#warningLinks li:last-child{ border-bottom:none;}
#warning h1 { font-size:30px; }
p.warningCopy{ font-size:18px; font-weight:normal; color:#333333; letter-spacing:0; line-height:24px; margin-bottom:25px;}
#warningWrapper.warningWrapper-EditMode {height:auto;}
#warningWrapper.warningWrapper-EditMode DIV {width: 600px;}
#warningWrapper.warningWrapper-EditMode #warningLinks DIV {width: auto;}



/* content */

#content{ width:100%; float:left;}
#contentWrapper{ width:960px; margin:17px auto 30px auto;}
.col645{ width:617px; float:left; margin-right:45px; margin-left:48px;}
.col645 p, .col645 ul { font-size:14px; }
.col645 h3{ font-size:28px; color:#0D4987; line-height:1.2em; }
.col250{ width:250px; float:left;}
.feature, .accordionItem{ width:570px; border-bottom:1px dashed #EBEBEB; padding:30px 15px 30px 60px; display:block; float:left;}
.feature { padding:25px 15px 25px 60px; }
.noPaddingLeft{ padding-left:0!important; width:630px!important;}
.col645 .noPaddingLeft{ padding-left:0!important; width:600px!important;}

.feature a, .accordionItem a{ font-size:14px; float:left;}
#ProductDetailExpandable .accordionItem a{float: none;}
.feature h6, .accordionItem h6{ color:#333333;}
.underline{ text-decoration:underline!important;}
.topBorder{ border-top:1px dashed #EBEBEB; margin-top:10px;}
.col645 h5{ color:#95807F; margin-bottom:0; float:left;}
.download{ background:url('../images/download.gif') no-repeat 5px 10px; width:485px; padding:20px 0px 15px 80px;}
.download-policy{ padding:20px 0px 15px 0px;}
.opener a{ display:block; float:right; width:16px; height:16px; background:url('../images/opener.gif') no-repeat 0 0; text-indent:-9999px;}
.accordionHead { background:url('../images/opener.gif') 570px 0px no-repeat; min-height:20px; height:auto !important; height:20px;}
.accordionHead a { float:left; }
.accordionHead:hover { cursor:pointer; text-decoration:underline;}
.expanded { background-position:570px -80px !important; }
.accordianWithIcon { padding:17px 0px 17px 0px; width:600px;}
.accordianWithIcon .expanded { background-position:570px -63px !important; }
#openAllLink { margin-right:5px; }
#openAllLink:hover { text-decoration:underline; }


.feature p, .accordionItem p{ margin-bottom:0;}
.feature p { margin-bottom:10px; }
 #FAQExpandable .accordionItem p{ margin-bottom:10px;}
.accordianWithIcon .accordionHead{ padding:0px 15px 30px 0px; background-position:570px 18px;}
.accordianWithIcon .accordionHead img { }
.accordianWithIcon .accordionHead a { margin-top:15px; }
.accordionBody { margin-top:15px; }
.promo{ padding:35px 10px 20px 80px; width:480px; position:relative; /*min-height:100px;*/}
.promo h3, .promo p { width:380px; float:left; }
.promo h3 { margin-bottom:4px; color:#000;}
.promo img { top:30px; right:0; position:absolute;}
.searchHeading{ background-color:#0D4987; color:#FFFFFF; font-weight:bold; padding:15px; font-size:16px;}
.defaultHeading{ color:#095D99 !important; font-size:32px !important; line-height:42px !important; margin-bottom:35px !important;}
h3.subtitle{ font-size:24px; color:#666666; font-weight:normal; margin-bottom:5px!important;}
p.subtitlePara{ font-size:16px; color:#685959; line-height:1.6em;}
p.MinorPara{ font-size:14px; color:#50504F; line-height:1.6em;}
.sideMod{ width:200px; float:left; background:url('../images/extra-bg.jpg') repeat-x #F7F7F7; padding:10px 25px; margin-bottom:25px;}
.sideMod h4{ margin-bottom:25px; font-weight:bold !important;}
.sideMod ul { padding-left:0; }
.sideMod ul li{ list-style:none; margin-bottom:15px;}

.claimHeading { color:#085C98 !important; font-size:22px !important; padding-top:20px !important; padding-bottom:10px !important;}
.claimHeading img { float:left; margin-top:-5px; margin-right:22px; }
ul.lists{ font-size:14px; margin-bottom:40px;}
ul.lists li{ margin-bottom:15px; color:#1D1D1B;}
h3.aboutHeading{ font-size:24px; color:#333333; margin-bottom:10px; margin-top:30px;}
h5.aboutSubtitle{ font-size:18px; color:#000!important; font-weight:normal; display:block; float:none; margin-bottom:5px;}
a.textDec{ text-decoration:underline;}
.borderImg{ border:3px solid #333333;  margin-right:30px;}
a .borderImg:hover{ border:3px solid #999;  margin-right:30px;}

.coummunityItem { margin-bottom:30px; }
.coummunityItem img {
margin-bottom:22px;	
}
.coummunityItem p { font-size:14px; }
.introduction { font-size:18px !important; color:#50504F !important; line-height:1.4em !important; margin-bottom:30px !important;}

/*pagination*/

#pagination { text-align:center; background-color:#F2F3F8; padding:15px 0;}
#pagination ul { margin-bottom:0;}
#pagination ul li{ list-style:none; display:inline;}
#pagination ul li a{ font-size:14px; padding:5px; }
#pagination a.prev{ background:url('../images/prev.gif') no-repeat 0 6px; padding-left:15px;}
#pagination a.next{ background:url('../images/next.gif') no-repeat right 6px; padding-right:15px;}



/* mega menu */

#megaMenu{  width:100%; position:absolute; z-index:66666; display:none; top:48px; float:left;}
#megaWrapper{ width:890px; margin: 0 auto; background-color:#FFFFFF; padding:25px 35px 35px 35px;}
.col185 {width:185px; float:left; }
.col245{ width:245px; border-right:1px dashed #EDEDED; float:left;}
.col230{ width:230px; border-right:1px dashed #EDEDED; float:left;}
#megaWrapper .col230 { padding-top:10px; }
.col120{ width:120px; float:left;}
.col430 { width:430px; float:left; }
.col245 h3, .col120 h3, .col230 h3{ font-size:16px; color:#0D4987;}
.col245 ul li, .col120 ul li, .col230 ul li{ margin-bottom:10px; list-style:none;}
.bgCar{ background:url('../images/menu-car.jpg') no-repeat 90% 0px;}
.bgContent{ background:url('../images/menu-content.jpg') no-repeat 90% 0px;}
.bgHouse{ background:url('../images/menu-house.jpg') no-repeat 90% 0px;}


#megaWrapper ul { padding:0;}
#mainNav #megaWrapper li a {border:none; font-size:1em; padding:0; }

#mainNav #megaWrapper li a:hover { background:none; border:none; color:#2E8ABF;}

#mainNav #megaWrapper li {
margin-bottom:10px;
display:block;	
}

/* mega menu basic functionality */
#seeAllPolicies {
position:relative;	
}


/* table */
#legacyProducts { margin-bottom:20px; width:960px; float:left; }
.tableHeaders h5{ font-size:16px; font-weight:normal; color:#666; line-height:1.2em;}
.tableHeaders h6{ font-size:16px; font-weight:normal; color:#666666;}
.tableHeaders h6.focus { color:#1D1D1B; }
#legacyProducts p { font-size:14px; }

a.downloadSmall{ background:url('../images/download-small.gif') no-repeat 0 0; padding-left:25px; display:block; height:30px; float:left; margin-right:5px;}
.tableDescription { width:535px; float:left;  min-height:22px; height:auto !important; height:20px; }
.tableDescription p { margin:0; padding:10px 0 5px; color:#0D4987;}
.tableShortCell { width:190px; float:left; text-align:center; padding:10px 0; min-height:22px; height:auto !important; height:22px; border-left:1px dashed #999;}
.tableShortCell p { margin:0; }
.tableRow { border-bottom:1px dashed #B0A1A4; float:left;  min-height:22px; height:auto !important; height:22px;}
.grayBG{ background-color:#F1F1F1;}
.grayBG p { color:#0D4987;}
.downloadLink { font-size:14px; color:#000000; }
.downloadLink a { text-decoration:underline !important; }
/* talk panel */

#talkPanelWrapper{ width:370px;  margin:0 auto; background-color:#FFFFFF; padding:25px;}
#panelHeader{  display:block; width:363px; height:86px; float:left; background:url('../images/panelHeader.png') no-repeat 0 0; margin-left:45px; margin-top:0px; position:relative;}
#close-btn a{ display:block; width:28px; height:28px; background:url('../images/close-btn.gif') no-repeat 0 0; position:absolute; right:10px; top:10px; text-indent:-9999px;}
#close-btn a:hover { background-position: 0px -56px; }
#contentWrapper label{ display:block; margin-left:45px; font-weight:bold; color:#0D4987; margin-bottom:3px; margin-top:10px;}
.marginLeft45{ margin-left:45px; }
.myDrop{ display:block; width:155px; padding:5px; -moz-border-radius: 8px;
border-radius: 8px;}
input, textarea{ -moz-border-radius: 5px; border-radius: 5px; }
.myTextArea{ height:80px; width:250px;  margin-bottom:10px;}
.marginLeft15{ margin-left:15px;}
.marginTop30{ margin-top:15px;}
.notes{ font-size:10px; color:#806C6B; line-height:1.3em;}
.errorLabel ,.errorLabel label{ color:#FF3300;}
input.errorLabel, select.errorLabel, textarea.errorLabel, .errorLabel input, .errorLabel select, .errorLabel textarea{ border:1px solid #FF3300!important; color:#000;}
.marginBottom0{ margin-bottom:0!important;}
#helpQuestion { margin-top:10px; }

/* LIGHT BOX */

#lightbox {
	
}

.blackOut {
background:#000;
-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; 
	filter: alpha(opacity=50);					
        -moz-opacity:0.5;
        -khtml-opacity: 0.5;
        opacity: 0.5;

width:100%;
min-height:900px;
height:auto !important;
height:900px;	
position:fixed;
top:0px;
z-index:77777;
}

#talkPanel {
/*position:fixed;*/
top:50px;
/*width:100%;*/
float:left;
z-index:8888;
display: block;	
}


#talkPanel fieldset {
float:left;
position:relative;
clear:left;	
}

#helpMessage {
display:none;
position:absolute;
padding:10px;
width:255px;	
-webkit-border-radius: 6px; -moz-border-radius: 6px; border-radius: 6px;
background:#fff;
border:1px solid #666;
top:-150%;
left:50%;
color:#87888A !important;
}

#legalFooterBlock a:hover {text-decoration: underline !important; color: #2E8ABF !important;}

#legalFooterBlock {
	text-align: right;
	width: 960px;
	margin: 0px auto;
	padding:20px 0 5px;
	clear:left;
	}

#legalFooterBlock p, #legalFooterBlock a, p.secondLine {color: #999 !important; font-size: 11px; margin:0;}
#legalFooterBlock a {margin-left:5px; text-decoration: none;}
#legalFooterBlock p.secondLine {color: #bbb;}

#overlay {
	width: 100%; 
	height: 100%; 
	background: #000; 
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999; 
	display: none; 
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; 
	filter: alpha(opacity=0);					
        -moz-opacity:0;
        -khtml-opacity: 0;
        opacity: 0;
}
#lightbox {
	z-index: 1000 !important;
	position: absolute;
	width: 370px;
}


#errorMessage p {
display:none;	
}

#errorMessage p.errorLabel {
display:block;	
margin-left:45px;
}

/* Fix the title of WebPart */
.ms-WPTitle {color: #003759 !important;}
.ms-WPTitle {font-family: Verdana, Tahoma, sans-serif !important;}
.ms-WPTitle {font-size: 10pt !important;}
.ms-WPTitle {font-weight: normal !important;}
.ms-WPTitle {margin: 0 !important;}
.ms-WPTitle {font-style: normal !important;}

/*Fix the font colour of the ribbon bar*/
.ms-siteactionsmenu > SPAN > A, .ms-welcomeMenu A:link, .ms-cui-tt .ms-cui-tt-span { color: #ffffff !important; }
.ms-cui-tt-s .ms-cui-tt-a .ms-cui-tt-span { color: #23272c !important; }

html, body {
     height: 100%
}
#s4-workspace {
     height: 100%;
}


#rightHandLinks .slm-edit-ecb
{
	padding-left: 50px;
}

#rightHandLinks .dfwp-list LI:first-child .slm-edit-ecb
{
	padding-top: 20px;
}

.contentList
{
	color: black;
	margin-left: 50px;
}

.contentList LI, .contentList P
{
	margin-bottom: 10px;
}

#FAQExpandable .accordionItem {padding-top: 12px; padding-bottom: 20px;}

#ProductDetailExpandable .accordionItem .accordionBody{
    margin-left: 70px;
}

#warningLinks .groupheader
{
	color: #80a1c2 !important;
	font-size: 20px !important;
	font-weight: normal !important;
	padding-bottom: 19px;
}

.dfwp-list
{
	margin-top: -5px;
}

.dfwp-list LI
{
	padding-bottom: 0px !important;
	padding-top: 0px !important;
}

#warningWrapper h1
{
	margin-top: 5px;
}
